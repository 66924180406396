.vue-password {
  position: relative;
}

.vue-password__input {
  position: relative;
}

.vue-password .form-control {
  width: 100%;
  padding-right: var(--spacing-xx-large);
}

.vue-password__toggle {
  position: absolute;
  right: var(--spacing-small);
  top: 50%;
  transform: translateY(-50%);
}

.vue-password__toggle-icon {
  user-select: none;
}

.vue-password__meter {
  width: 100%;
  height: 0.4rem;
  margin-top: var(--spacing-xx-small);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: var(--spacing-xx-small);
}

.vue-password__meter-unit {
  border-radius: 0.2rem;
  background: var(--color-gainsboro);
  transition: background-color .2s;
}

.vue-password--weak  {
  background-color: var(--color-danger);
}

.vue-password--medium {
  background-color: var(--color-warning);
}

.vue-password--good, .vue-password--great {
  background-color: var(--color-success);
}

.vue-password__message {
  margin-top: var(--spacing-xxx-small);
  text-align: right;
  text-transform: uppercase;
}
