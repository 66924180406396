.banner {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.banner--warning {
  background: var(--color-warning);
}

.banner__button {
  padding-left: var(--spacing-large);
}
