
                 @import "_variables.scss";
              

















































































.tagsbar__custom-select .custom-select {
  height: 46px;
}

.tags-picker-action{
  padding: 6px 12px;
  display: block;
  cursor: pointer;
}

.tags-picker-icon{
  display: inline-block;
  width: 28px;
  margin-left: -6px;
  text-align: center;
}
