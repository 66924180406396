.modal-backdrop.in {
  background-color: var(--color-charcoal);
}

.modal-content {
  border: none;
  border-radius: var(--radius-large);
  box-shadow: var(--shadow-large);
  max-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}

.modal-header {
  @extend .u-clearfix;
  padding: var(--spacing-x-large) var(--spacing-x-large) var(--spacing-large);

  & .close {
    margin-top: -1.4rem;
  }
}

.modal-body {
  padding: var(--spacing-small) var(--spacing-x-large) var(--spacing-x-large);
  flex: 1;
  overflow: auto;
}

.modal-footer {
  @extend .u-clearfix;
  padding: var(--spacing-small) var(--spacing-x-large) var(--spacing-x-large);
  text-align: right;
}

@media (min-width: $screen-sm-min) {
  .modal-dialog {
    margin: 100px auto;
  }

  .modal-content {
    max-height: calc(100vh - 200px);
  }
}

.modal-error {
  .modal-title {
    color: var(--color-danger);
  }
}
