.color-picker {
  width: 26.0rem;
  position: relative;
}

.color-picker__display {
  width: var(--dimension-form-height);
}

.color-picker__dropdown {
  padding: var(--spacing-medium);
}

.color-picker__swatch {
  display: inline-block;
  cursor: pointer;
  min-width: 20px;
  height: 20px;
  margin: 0 0.4rem 0.2rem 0;
  border-radius: var(--radius-small);
}
