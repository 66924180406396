
                 @import "_variables.scss";
              


































































.wt-header{
  width: 100%;
}

.wt-menu {
  min-width: 250px;
  padding-right: 60px;
}

.wt-menu h5 {
  margin-bottom: var(--spacing-large);
  padding-left: var(--spacing-small);
  padding-right: 60px;
}

.wt-menu ul {
  margin: 0;
  padding: 0;
}

.wt-menu li {
  list-style: none;
}
