.container--metrics {
  max-width: 900px;
  width: 100%;
  padding: var(--spacing-large) var(--spacing-medium);
  margin: 0 auto;
}

.metric-grid--compact {
  max-width: 700px;
  margin: 0 auto;
}

.metric--big-first {
  grid-row: span 2;
  grid-column: span 2;
}

@media (max-width: 1100px) {
  .metric--small-fix-span {
    grid-column: span 2;
  }
}
