.http-code-error {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-bottom: 100px;
}

.http-code-error__visual {
  position: relative;
}

.http-code-error__visual-content {
  position: absolute;
  top: 55%;
  left: 36%;
  transform: translateX(-50%) translateY(-50%);
}

.http-code-error__status {
  font-size: 58px;
  color: #ffffff;
  font-weight: 700;
}

.http-code-error__info {
  padding-left: 100px;
}
