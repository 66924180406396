.datasource-endpoint {
  padding-right: var(--spacing-medium);
  margin: 0 0 var(--spacing-x-large);
  width: 50%;
}

@media(max-width: $screen-xs-max) {
  .datasource-endpoint {
    width: 100%;
  }
}

.endpoint-panel {
  max-height: 400px;
  max-width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
