.bs-dropdown .active a {
  position: relative;
}

.bs-dropdown a {
  padding-left: 25px !important;
}

.bs-dropdown .active a:before {
  content: "\f00c";
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  color: #000;
  font-size: 18px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.dropdown-inline {
  display: inline-block;
}


/**
  Search results used in admin providers lookup
 */
.search-results {
  position: absolute;
  background: var(--color-white);
  width: 100%;
  border: 1px solid #e6eaee;
  max-height: 500px;
  overflow: auto;
  z-index: 1;
}

.search-results > div {
  cursor: pointer;
  padding: 10px;
}

.search-results > div:hover {
  background: darken(#ffffff, 5%);
}

.search-results > div:not(:last-child) {
  border-bottom: 1px solid #e6eaee;
}

// Used in WidgetDetail.vue
.dropdown-container {
  background-color: $dropdown-bg;
  border: solid 1px $dropdown-border;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  z-index: 1000;
  top: 100%;

  .dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dropdown-link-color;
    padding: 6px 12px;

    &:hover {
      cursor: pointer;
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}
.dropdown-container-left{
  left: 0;
}

.dropdown-container-right{
  right: 0;
}