


// COLORS

$color-white: #ffffff;
$color-black: #000000;

$color-primary: #498fe1;
$color-secondary: #104680;

$color-bg-primary: #fafafa;
$color-bg-secondary: #f3f3f3;

$color-text-primary: #40546f;
$color-text-secondary: #8dabc4;

$color-gray-lighter: #f1f5ff;
$color-gray-light: #e5e9f3;
$color-gray: #cbcfd9;
$color-gray-dark: #727680;
$color-gray-darker: #595d67;



// TYPOGRAPHY

$font-family: "Roboto", sans-serif;
$font-size: 14px;



// DIMENSIONS

$radius-small: 2px;
$radius-base: 2px;
$radius-large: 4px;

