.filter-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -10px;
}

@media(max-width: 768px) {
  .filter-form {
    flex-direction: column;
  }

  .filter-group {
    flex: 1;
    margin-right: 0 !important;
  }

  .filter-group > label {
    flex-basis: 30%;
    margin: 0;
  }

  .filter-group > *:last-child {
    width: 100% !important;
    flex-basis: 70%;
  }
}

.filter-form > * {
  margin-top: 10px;
}

.filter-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter-group {
  margin-right: 10px;
}

.filter-group > label {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0;
}

.filter-group > input[type=search] {
  width: 250px;
  max-width: 100%;
}

@media(min-width: 769px) {
  .filter-dropdown {
    max-width: 250px;
  }
}

.filter-dropdown > div {
  padding-right: 16px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
