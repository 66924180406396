.main-content {
  flex: 1;
  min-height: 0;
  align-self: center;
  width: $container-width;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

@media (max-width: $container-width) {
  .main-content {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.main-content--full-width {
  width: 100%;
  padding: 0;
}

.main-content--compact {
  width: var(--dimension-panel--compact);
}

.main-content--iframe-alignment {
  align-self: var(--iframe-alignment);
  padding: 0 var(--spacing-medium);
}
