.nav-menu {
  position: fixed;
  top: calc(var(--dimension-height-header) - 1.2rem);
  right: var(--spacing-xxx-large);
  display: flex;
  flex-direction: column;
  width: var(--dimension-width-nav-menu);
  border-radius: var(--radius-large);
  padding: calc(var(--spacing-medium) - 0.2rem) var(--spacing-medium);
  z-index: var(--z-index-nav-menu);
  background-color: var(--color-white);
  box-shadow: var(--shadow-large);
}

.nav-menu__item {
  display: flex;
  align-items: center;
  font-family: var(--font-family--heading);
  font-weight: 400;
  line-height: 1;
  padding: 1.2rem;
  transition: color var(--timing-fast), fill var(--timing-fast);
  color: var(--color-text);
  fill: var(--color-text);

  &:focus, &:hover {
    color: var(--color-primary);
    fill: var(--color-primary);
  }
}

.nav-menu__item--danger {
  &:focus, &:hover {
    color: var(--color-danger);
  }
}

.nav-menu__icon {
  display: inline-block;
  margin-right: var(--spacing-medium);
  max-height: var(--font-size-body);
  max-width: var(--font-size-body);
}

.nav-menu__divider {
  margin: var(--spacing-small);
  border-color: var(--color-concrete);
}

.nav-menu__divider + .nav-menu__divider {
  display: none;
}

.nav-menu-transition-enter-active, .nav-menu-transition-leave-active {
  transition: all var(--timing-medium);
}

.nav-menu-transition-enter, .nav-menu-transition-leave-to {
  opacity: 0;
  transform: translateY(-1.2rem);
}

@media (max-width: $screen-md-max) {
  .nav-menu {
    right: var(--spacing-large);
  }
}

@media (max-width: $screen-xs-max) {
  .nav-menu {
    width: 100%;
    border-radius: 0;
    top: var(--dimension-height-header);
    right: 0;
    height: calc(100vh - var(--dimension-height-header));
  }
}
