
                 @import "_variables.scss";
              
































































































.input-image-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px dashed #898989;
  color: #898989;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.input-image-container:hover {
  color: #898989;
  border: 2px dashed #898989;
}

.input-image-container > img {
  max-height: 100%;
  max-width: 100%;
  padding: 5%;
}

.input-image-container > .fa-times {
  position: absolute;
  top: -5px;
  right: -5px;
  color: var(--color-danger);
}

.input-image-container:hover > .fa-times {
  top: -6px;
  right: -6px;
}
