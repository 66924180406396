
                 @import "_variables.scss";
              





































































.multi-dropdown {
  border: 1px solid #ccc;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0;
  background: var(--color-white);
  z-index: 500;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
}

.multi-dropdown > li {
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
}

.multi-dropdown > li:hover {
  cursor: pointer;
  color: #536c8f;
  background: #e6e5e5;
}

.multi-dropdown > li > p {
  margin: 10px 0 0 10px;
}

.multi-dropdown-active{
  border-color: #66afe9 !important;
  outline: 0 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
}

.multi-dropdown-readonly{
  background-color: #eee!important;
  opacity: 1 !important;
}
