.list {
}

.list > div {
  margin-bottom: 15px;

  &:not(.alert) {
    background: var(--color-white);
  }
}

.list > div > div {
  padding: 10px;
  border: 1px solid #e3e3e3;
  box-shadow: 0 1px #fff inset;
}


.list-title {
  font-weight: 400;
  font-size: 16px;
  color: #3f536e;
  overflow: hidden;
}

.list > div.list-title {
  border-bottom: 2px solid #3092ed;
}

.list > div.list-title > div {
  border-bottom: 0;
  text-align: center;
}


.list > div.list-panel {
  border-left: 4px solid #3092ed;
}

.list > div.list-panel > div {
  border-left: 0
}

.list-collapsible > div > div.list-title {
  cursor: pointer;
}

.list-collapsible:not(.list-no-icon) > div > div.list-title:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  display: inline-block;
  align-self: center;
  font-size: 14px;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 8px;
  transition: all .1s ease;
  content: "\f105";
}

.list-collapsible:not(.list-collapsible-open) > div > div.list-body {
  display: none;
}

.list-collapsible-open:not(.list-no-icon) > div > div.list-title:before {
  transform: rotate(90deg);
}

.list-body {
  margin-top: 10px;
  padding: 5px;
  margin-left: 5px;
  max-height: 550px;
  overflow: auto;
}


.list-option{
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  cursor: pointer;
  overflow: hidden;
}

.list-option:hover{
  background: #ecf5fc;
  color: #3092ed;
}

.list-error{
  background-color: #f2dede !important;
  border-color: #d0011b !important;
}

.list-error > div{
  box-shadow: 0 1px #f2dede inset !important;
}