.main-dashboard {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
  color: $gray-dark;
  justify-content: space-around;
  max-width: 1140px;
}

.dashboad-step {
  text-align: center;
  width: 290px;
  margin-bottom: 40px;
  a:first-child {
    display: block;
    height: 290px;
  }
  p {
    font-size: 18px;
    margin-bottom: 35px;
    height: 100px;
  }
}

.image-arrow {
  margin-top: 144px;
  img {
    height: 48px;
    width: 59px
  }
  @media (max-width: 1140px){
    display: none;
  }
}

.main-dashboard h3 {
  color: #585858;
  margin-top: 41px;
  margin-bottom: 48px;
  font-weight: 300;
}


.main-dashboard a {
  font-size: 16px;
  text-decoration: underline;
  > img {
    width: 280px;
  }
}

