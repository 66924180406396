
                 @import "_variables.scss";
              




















































































































































































































































































































































.we-list-active {
  color: var(--color-link);
}

.widgets-index-list-item {
  cursor: pointer;
}

.widgets-index-list-item img {
  max-height: 24px;
  max-width: 24px;
}

.widgets-index-list-item img + img {
  margin-left: 5px
}

.widgets-index-list-item td {
  vertical-align: middle !important;
}

.widgets-index-list-item td a {
  display: block;
  min-height: 100%;
  width: 100%;
}

.widgets-index-list-item:hover {
  background-color: var(--color-background--accent)
}

.we-row-item{
  padding: 0!important;
}

.we-row-item:not(:hover) .fa-tag{
  display: none !important;
}
