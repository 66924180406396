
                 @import "_variables.scss";
              


































































.widget-list-item {
  display: block;
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  color: inherit;
  transition: color 100ms, box-shadow 100ms;
}

 .widget-list-item:hover {
   color: inherit;
   box-shadow: var(--shadow-medium);
   transition: box-shadow 100ms;
 }

 .widget-list-item:hover .widget-list-item__title {
   color: var(--color-primary);
   transition: color 100ms;
 }

 .widget-list-item:hover .widget-list-item__caret {
   color: var(--color-primary);
   transition: color 100ms;
 }

 .widget-list-icons {
   width: 135px;
   margin-right: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .widget-list-icons img {
   max-height: 36px;
   margin-left: 8px;
   margin-right: 8px;
 }

 .widget-list-description {
   word-break: break-word;
   max-width: 180px;
   max-height: 40px;
   margin-bottom: 0;
   margin-left: 8px;
   font-size: var(--font-size-small);
 }

 .widget-list-body {
   flex: 1;
   max-height: 45px;
   font-size: 14px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }

 .widget-list-body__text {
   display: flex;
   flex-direction: column;
 }

 .widget-list-body__tags {
   display: flex;
   align-items: center;
 }

 .widget-list-actions {
   margin-left: 2em;
   margin-right: 2em;
   display: flex;
   align-items: center;

 }

 .widget-list-item__caret {
   display: flex;
   align-items: center;
   font-size: 3rem;
 }
