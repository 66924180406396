.bar-chart-title {
  display: inline-block;
  margin-bottom: 67px;
  margin-right: 78px;
}

.bar-chart-dropdown {
  display: inline-block;
  background: transparent;
  padding: 10px;
  border: none;
}

.bar-chart {
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  .first-row {
    height: 170px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .legend-y {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: var(--color-text--muted);
      margin-right: 50px;
      padding-bottom: 5px;
    }
    .bars {
      width: 930px;
      display: flex;
      height: 100%;
      flex-direction: row;
      justify-content: flex-end;
    }
    .bars > div {
      background: var(--color-background--secondary);
      height: 100%;
      width: 14px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-radius: var(--radius-medium);
    }

    .bars > div:not(:last-child) {
      margin-right: 16px;
    }
    .bars > div > div {
      background: var(--color-qlik-green);
      width: 100%;
      border-radius: var(--radius-medium);
    }
  }

  .legend-x {
    flex-basis: 930px;
    align-self: flex-end;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--color-text--muted);
    margin-left: 67px;
    padding-bottom: 25px;
    padding-top: 20px;
  }
}