.datasource-tile {
  position: relative;
  height: 82px;
  border-radius: var(--radius-large);
  padding: 0 var(--spacing-small);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--color-background--accent);

    .datasource-tile__actions {
      opacity: 1;
    }
  }
}

.datasource-tile--disconnected:hover {
  background-color: rgba(var(--rgb-danger), 0.25);
}

.datasource-tile__logo {
  width: 46px;
  height: 46px;
  background-size: cover;
  margin-left: var(--spacing-medium);
}

.datasource-tile__info {
  flex: 1;
  flex-direction: column;
  margin: 0 var(--spacing-large);
}

.datasource-tile__name {
  margin: var(--spacing-xxx-small) 0;
}

.datasource-tile__instance {
  max-height: 34px;
  overflow: hidden;
  word-break: break-word;
  font-size: var(--font-size-small);
}

.datasource-tile__actions {
  padding-left: var(--spacing-x-large);
  opacity: 0;
  transition: opacity var(--timing-fast);
}
