.file-upload {
  border-width: 2px;
  border-style: dashed;
  border-radius: var(--radius-small);
  transition: border-color var(--timing-fast);
}

.file-upload__area-toolbar {
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 var(--spacing-medium);
  cursor: pointer;
  border-bottom-right-radius: var(--radius-small);
  border-bottom-left-radius: var(--radius-small);
  background: var(--color-cultured);
  border-top: dashed 1px var(--color-gainsboro);
  color: var(--color-text--muted);
  font-weight: var(--font-weight-label);
  font-size: var(--font-size-small);
}

.file-upload--control {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  height: 56px;
  border-color: var(--color-gainsboro);
  cursor: pointer;
}

.file-upload--area {
  border-color: transparent;
}

.file-upload--hover {
  border-color: var(--color-accent);
}
