
                 @import "_variables.scss";
              






















































































































































.list-enter-active, .list-leave-active {
  transition: all .7s;
}

.list-enter {
  opacity: 0;
  transform: translateY(30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
