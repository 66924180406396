.datasourcetype-card {
  position: relative;
  height: 210px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--spacing-large);

  &:hover {
    .datasourcetype-card__actions {
      opacity: 1;
    }
  }
}

.datasourcetype-card--integration {
  height: 180px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.datasourcetype-card__logo {
  width: 65px;
  height: 65px;
  background-size: cover;
}

.datasourcetype-card__name {
  display: inline-block;
  margin-top: var(--spacing-medium);
  margin-bottom: var(--spacing-xx-small);
  text-transform: capitalize;
  font-weight: 700;
}

.datasourcetype-card__group {
  font-size: var(--font-size-small);
  color: var(--color-text--muted);
}

.datasourcetype-card__actions {
  position: absolute;
  left: var(--spacing-large);
  bottom: var(--spacing-large);
  opacity: 0;
}
