.simple-bar-chart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.simple-bar-chart__bar {
  height: 1.2rem;
  border-radius: 0.6rem;
  background-color: var(--color-accent);
  min-width: 1.2rem;
}

.simple-bar-chart__bar--empty {
  background-color: var(--color-silver-chalice);
}
