// TODO: Split file and classes up with meaningful names


.table {
  margin-bottom: 0;

  th > .far, th > .fas {
    font-size: 14px;
  }
}

.panel .form-buttons {
  margin-top: $container-spacing;
}

.container-medium {
  width: $container-width;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.checkbox label{
  font-size: 14px !important;
}

.delete-btn {
  color: #b2b3bf;
  border: 1px solid #b2b3bf;
  display: inline-block;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 8px;
  vertical-align: text-top;
  margin-left: 10px;

  i {
    margin-top: -1px;
    line-height: 16px;
    font-size: 1.5em;
  }
}

.delete-btn i:before {
  content: '\00d7';
}

.delete-btn:hover, .delete-btn:active {
  color: #40546f;
  border-color: #40546f;
}

.link-unstyled, .link-unstyled:link{
  background: none;
  border: 0;
  background: transparent;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
}

.helper-block {
  background: var(--color-white);
  border-radius: 4px;
  border: 1px solid #e6eaee;
  max-width: 265px;
}

.helper-block > div:first-child {
  margin: 30px;
}

.helper-block .title {
  color: #fc6e4f;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 14px;
  white-space: nowrap;
}

.helper-block .button-menu {
  height: 70px;
  background-color: #f3f7f9;
  line-height: 70px;

  a {
    font-size: 14px;
    font-weight: 700;
    color: #323c47;
    text-transform: uppercase;
  }

  i:first-child {
    color: #fc6e4f;
  }

  span {
    margin-left: 20px;
    margin-right: 6px;
  }
}

.pull-top-right {
  position: absolute;
  right: 15px;
  top: 15px;
}

.btn-extra-margin {
  margin-top: 40px;
  margin-bottom: 70px;
}

.btn-dropdown {
  color: #808fa3;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #dae8f0;
  font-weight: 400;
  border-radius: 5px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 16px;

  i {
    display: inline-block;
    vertical-align: text-bottom;
    padding-left: 65px;
    padding-right: 3px;
  }
}

.datasourcelogo {
  width: 50px;
  height: 50px;
  margin: 5px;
}

img.header-logo {
  height: 40px;
  display: block;
  margin: 20px auto 50px;
}

.center-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

/** Breadcrumbs **/
.breadcrumbs-container {
  display: flex;
  justify-content: space-between;
  width: $container-width;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;

  &:empty {
    display: none;
  }
}

.breadcrumbs {
  font-size: 24px;
  padding-bottom: 20px;
  padding-top: 30px;
  margin-top: 0; // Margin top is set by main div
}

.breadcrumbs-navigation {
  justify-self: flex-end;
  position: relative;
  margin-bottom: 10px;
  padding-top: 30px;
  font-size: 16px;
  margin-left: auto;

  .btn {
    margin-right: 10px;
  }

  .btn:last-child {
    margin-right: 0;
  }
}

.tabs li a {
  @media(max-width: 768px) {
    font-size: 24px !important;
  }
}

.header-container {
  display: flex;
  flex-wrap: wrap;

  & > :first-child {
    flex: 1;
    min-width: 450px;
    margin-bottom: 20px;
    margin-right: 20px;
    @media(max-width: 450px) {
      min-width: 100% !important;
      width: 100%;
    }
  }
}

.error {
  background-color: var(--color-danger);
  padding: 5px;
  color: var(--color-white);
}

input[type=radio] {
  margin-right: 10px;
}

.modal-body:empty,
.modal-footer:empty {
  display: none;
}

i {
  &.fa, &.fas, &.far, &.fal, &.fab {
    &.disabled {
      cursor: not-allowed !important;
      filter: grayscale(100%);
      opacity: 0.6;
    }
  }
}

.has-search .form-control-feedback {
  right: initial;
  left: 0;
  color: #ccc;
}

.has-search .form-control {
  padding-right: 12px;
  padding-left: 34px;
}

.overflow {
  overflow: auto
}

.text-break {
  word-break: break-word;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    text-align: left;
  }
}

.btn-transparent {
  background-color: inherit !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-transparent:hover, .btn-transparent:focus {
  color: inherit !important;
}

.v-center{
  vertical-align: middle !important;
}

.inline-img{
  height: 1em;
  vertical-align: top;
}

.inline-img-15{
  height: 1.5em;
  vertical-align: top;
}

.scrollable-paragraph {
  word-break: break-word;
  max-height: 500px;
  overflow: auto;
}

.icons-big {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  justify-content: center;

  img {
    align-self: center;
    max-width: 85px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.fa-14x{
  font-size: 140%;
  line-height: 14px;
  height: 14px;
}

.text-overflow {
  display: block;
  hyphens: none;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  white-space: nowrap!important;
  width: 100%!important;
}

.fs-16 {
  font-size: 16px;
}

.alert-close {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}