
                 @import "_variables.scss";
              












































































































.widget-template-detail {
  background: var(--color-white);
}

.widget-template-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 4em;
}

.widget-template-icons img {
  display: block;
  height: 75px;
  margin-left: 5px;
  margin-right: 5px;
}
