
                 @import "_variables.scss";
              
// SETTINGS
@import './theme-variables';
@import './variables'; // Variables mainly used by bootstrap

// 3rd-party
@import 'node_modules/codemirror/lib/codemirror';

@import '~@qlik-trial/automations-ui/dist/automations-ui';

// ELEMENTS
@import './elements/main';


// COMPONENTS
@import './pages/account';
@import './pages/main_dashboard';
@import './pages/widgets';
@import './pages/datasourceintegration';

@import './components/app';
@import './components/main-content';
@import './components/header';
@import './components/nav-menu';
@import './components/layout';
@import './components/markdown';
@import './components/modal';
@import './components/bar-chart';
@import './components/cards';
@import './components/containers';
@import './components/banner';
@import './components/bs-dropdown';
@import './components/list';
@import './components/filter-form';
@import './components/http-code-error';
@import './components/close';
@import './components/vue-password';
@import './components/header-paragraph';
@import './components/color-picker';
@import './components/social-buttons';
@import './components/datasource-overview';
@import './components/datasource-type-card';
@import './components/highlight';
@import './components/metrics';
@import './components/simple-bar-chart';
@import './components/file-upload';
@import './components/datasource-tile';

// UTILITIES
@import './utilities/core-specific';
