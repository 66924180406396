.btn-social {
  display: flex;
  flex-direction: row;
  color: var(--color-text--inverse);
  justify-content: space-between;
  align-items: center;
  width: 180px;
  height: 34px;
  border-radius: var(--radius-medium);
  border: none;
  outline: 0;
  margin: 4px;
  padding: 1px;
}

.btn-social__title {
  flex: 1;
  font-weight: 600;
  line-height: 1;
  font-size: var(--font-size-small);
}

.btn-social i {
  line-height: 32px;
  font-size: 1.8rem;
  text-align: center;
  width: 32px;
}

.btn-google img {
  background: #fff;
  width: 32px;
  height: 32px;
  border-radius: calc(var(--radius-medium) - 0.1rem);
}

.btn-google {
  background: #5886d4;
}

.btn-google:hover {
  background: #4776c4;
}

.btn-twitter {
  color: var(--color-text--inverse);
  background-color: #55acee;
}
