.container-with-menu {
  margin-left: auto;
  margin-right: auto;
  width: $container-width;
  max-width: 100%;
  display: flex;

  @media(max-width: 850px) {
    flex-direction: column;
  }
}

.container-with-menu .left-menu {
  min-width: 270px;
  flex-basis: 270px;
  padding-right: var(--spacing-xx-large);
  margin-bottom: 15px;

  hr {
    margin: var(--spacing-medium) var(--spacing-small);
  }

  @media(max-width: 850px) {
    flex-basis: 100%;
    padding-right: 0;
    li:not(.active) {
      height: 0;
    }

    hr {
      display: none;
    }

    li.active:after {
      cursor: pointer;
      content: '\f0c9';
      position: absolute;
      right: 10px;
      top: 6px;
      font-family: 'Font Awesome 5 Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 1.5em;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul.expanded > li {
    height: 40px !important;
  }

  ul.expanded hr{
    display: block !important;
  }

  li {
    overflow: hidden;
    border-radius: var(--radius-small);
    transition: height 100ms;
  }

  li a {
    color: var(--color-text);
    display: block;
    padding: 1.2rem var(--spacing-small);
    line-height: 1;
    user-select: none;

    &:hover, &:focus {
      background: var(--color-background--accent);
    }
  }

  li.active {
    position: relative;
  }

  li.active a {
    background: var(--color-gainsboro);
    font-weight: 600;
  }
}

.container-with-menu .content {
  flex: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  min-width: 0;
}
