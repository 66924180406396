.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: var(--z-index-header);
  width: 100%;
  padding: 0 var(--spacing-xxx-large);
  height: var(--dimension-height-header);
  background-color: rgba(var(--rgb-concrete), 0.95);
}

@supports(backdrop-filter: saturate(140%) blur(0.6rem)) {
  .header {
    background-color: var(--color-header);
    backdrop-filter: saturate(140%) blur(0.6rem);
  }
}

.header__logo {
  height: 2.6rem;
}

.header__user {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header__name {
  margin: 0 0 var(--spacing-xxx-small);
}

.header__caret {
  transition: transform var(--timing-fast);
  font-size: 3.6rem;
  transform: translateY(0.4rem);
}

.header__caret--open {
  transform: scaleY(-1) translateY(0.4rem);
}

.header__question {
  font-size: 2.2rem;
}

@media (max-width: $screen-md-max) {
  .header {
    padding: 0 var(--spacing-large);
  }
}

@media (max-width: $screen-sm-max) {
  .header__question {
    display: none;
  }
}

@media (max-width: $screen-xs-max) {
  .header__logo {
    height: 1.8rem;
  }
}
