.container-cards {
  width: calc(100% + 15px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  &.active {
    display: flex !important;
  }
  .card {
    position: relative;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e6eaee;
  }

  a.card {
    display: block;
  }

  > * {
    min-width: 240px;
    margin-right: 15px;
  }

  .ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
  }
  .card .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #6EBBEA;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
  }
  .card .ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border: 3px solid #6EBBEA;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
  .card .ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border: 3px solid #6EBBEA;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.container-cards:not(.container-cards-1) > * {
  margin-bottom: 40px;
}

.container-cards.container-cards-1 > *:not(:last-child) {
  margin-bottom: 40px;
}


.container-cards-1 > * {
  width: calc(1 / 1 * 100% - 15px);
}

.container-cards-2 > * {
  width: calc(1 / 2 * 100% - 15px);
}

.container-cards-3 > * {
  width: calc(1 / 3 * 100% - 15px);
}

.container-cards-4 > * {
  width: calc(1 / 4 * 100% - 15px);
}

.container-cards-5 > * {
  width: calc(1 / 5 * 100% - 15px);
}

@media (max-width: 515px) {
  .container-cards-5,
  .container-cards-4,
  .container-cards-3,
  .container-cards-2 {
    justify-content: center;
  }
  .container-cards-2 > * {
    width: calc(100% - 15px);
  }
}
