.datasource-overview {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-gap: var(--spacing-x-large);
  margin-top: var(--spacing-xx-large);
}

.datasource-overview__heading {
  grid-column: 2;
}

.datasource-overview__menu {
  grid-column: 1;
  grid-row: 2;
}

.datasource-overview__tiles {
  grid-column: 2;
  grid-row: 2;
  display: grid;
  grid-template-rows: repeat(auto-fit, 210px);
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-gap: 0;
}

.datasource-overview__tiles--integrations {
  grid-template-rows: repeat(auto-fit, 180px);
}

@media (max-width: $screen-sm) {
  .datasource-overview__menu {
    display: none;
  }

  .datasource-overview__heading {
    grid-column: 1 / 3;
  }

  .datasource-overview__tiles {
    grid-column: 1 / 3;
  }
}

.menu-title {
  margin-left: 10px;
  margin-bottom: 30px;
}
