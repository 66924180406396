
                 @import "_variables.scss";
              






































































































































.wz-header {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.wz-step {
  position: relative;
}

.wz-step-number {
  background: var(--color-silver-chalice);
  color: var(--color-text--inverse);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto auto 25px;
}

.wz-step-number--active {
  background: var(--color-primary);
}

.wz-step-title {
  text-align: center;
  width: 200px;
}

.wz-step-dots {
  margin-top: 25px;
  margin-left: 10px;
  margin-right: 10px;
  width: 150px;
  border-top: 1px dashed var(--color-silver-chalice);
}
